<div class="brochure">

    <section class="cover brochure__cover">
        VRIJBLIJVENDE INFORMATIE OVER <br>
        <h1>{{building.street}} {{building.houseNumber}} <br /> {{building.place}}</h1>
        <img [src]="building.mainImage" alt=""><br>
    </section>
    <div class="page-break"></div>
    <section class="brochure__page">
        <h1>Algemene gegevens</h1><br>
        <table>
            <tr>
                <td>
                    <p>Adresgegevens</p>
                </td>
                <td>
                  {{building.street}} {{building.houseNumber}}
                  <br /> {{building.postalCode}} {{building.place}} <br />
                  <img class="brochure__gmap" [src]="imageMap" *ngIf="imageMap"> </td>
            </tr>
            <tr>
                <td>
                    <p>Oppervlakte</p>
                </td>
                <td>{{building.surfaceSize}} m² b.v.o.</td>
            </tr>
            <tr *ngIf="building.cadastralData">
                <td>
                    <p>Kadastrale gegevens</p>
                </td>
                <td>{{building.cadastralData}}</td>
            </tr>
            <!-- <tr *ngIf="building.status.id == 2">
                <td>
                    <p>Vrije overspanning/Vrij hoogte: </p>
                </td>
            </tr> -->
            <tr *ngIf="building.frontWidth">
                <td>
                    <p>Frontbreedte</p>
                </td>
                <td>{{building.frontWidth}}</td>
            </tr>
        </table>

    </section>
    <section class="brochure__page">
        <h1>Omschrijving</h1>
        <table>
            <tr>
                <td>
                    <p>Object/Locatie</p>
                </td>
                <td class="rte-text"><div  [innerHTML]="building.description"></div> </td>
            </tr>
            <tr *ngIf="building.reachability">
                <td>
                    <p>Bereikbaarheid</p>
                </td>
                <td [innerHTML]="building.reachability"></td>
            </tr>
            <tr *ngIf="building.summary">
                <td>
                    <p>Omschrijving</p>
                </td>
                <td [innerHTML]="building.summary"></td>
            </tr>
            <tr *ngIf="building.facilities">
                <td>
                    <p>Voorzieningen</p>
                </td>
                <td [innerHTML]="building.facilities"></td>
            </tr>
            <tr *ngIf="building.parking">
                <td>
                    <p>Parkeren</p>
                </td>
                <td [innerHTML]="building.parking"></td>
            </tr>
            <tr *ngIf="building.destination">
                <td>
                    <p>Bestemming</p>
                </td>
                <td [innerHTML]="building.destination"></td>
            </tr>
        </table>
    </section>
    <div class="page-break"></div>
    <section class="brochure__page">
        <div *ngIf="building.status.id == 1 || building.status.id == 3">
            <h1>Omschrijving verhuur</h1><br>
            <table>
              <ng-container *ngFor="let key of rentalTermsOrder">
                <tr *ngIf="building.rentalTerms [key]">
                    <td>{{ 'term-' + key | fromDictionary}}</td>
                    <td class="rte-text" [innerHTML]="building.rentalTerms[key]">
                </tr>
              </ng-container>
            </table>
        </div>

        <div *ngIf="building.status.id == 2 || building.status.id == 3">
            <h1>Omschrijving verkoop</h1><br>
            <table>
                <tr *ngIf="building.purchaseTerms?.purchasePriceSpecification && false">
                    <td>
                        <p>Vraagprijs</p>
                    </td>
                    <td  class="rte-text" [innerHTML]="building.purchaseTerms?.purchasePriceSpecification"></td>
                </tr>

              <ng-container *ngFor="let key of purchaseTermsOrder">
                <tr *ngIf="building.purchaseTerms [key]">
                    <td>{{ 'term-' + key | fromDictionary}}</td>
                    <td class="rte-text" [innerHTML]="building.purchaseTerms[key]">
                </tr>
              </ng-container>


            </table>
        </div>

        <div class="">
            <h1>Meer informatie</h1><br/>
            <table>
                <tr>
                    <td>Voor meer informatie<br /> verwijzen wij u naar</td>
                    <td>
                        <p>
                            <strong>Muller Bedrijfs Onroerend Goed</strong><br /> {{ 'footer-adres-1'| fromDictionary}}<br /> {{ 'footer-adres-2'| fromDictionary}}<br />
                        </p>
                        <p>
                            {{ 'footer-adres-1'| fromDictionary}}<br /> {{ 'footer-adres-2'| fromDictionary}}
                        </p>
                        <p>
                            T: 038 - 423 71 11<br /> E: info@mullerbog.nl<br /> W: www.mullerbog.nl
                        </p>
                    </td>
                </tr>
            </table>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p><em>De informatie in deze brochure is met de grootste zorg samengesteld. Echter bestaat altijd de mogelijkheid op afwijkingen en/of onjuistheden ten opzichte van de werkelijkheid. Zowel Muller Bedrijfs Onroerend Goed als de eigenaar zijn niet aansprakelijk voor mogelijke onjuistheden in de informatie.</em></p>
        </div>
    </section>

    <div class="page-break"></div>

    <section class="brochure__page" *ngIf="building.gallery.length > 0">
        <h1>Foto's</h1><br>
        <ul class="photo-gallery">
            <li *ngFor="let i of building.gallery">
                <img [src]="i.thumbnail" alt="">
            </li>
        </ul>
    </section>

    <div class="page-break" *ngIf="building.floorplans.length > 0"></div>

    <section class="brochure__page" *ngIf="building.floorplans.length > 0">
        <h1>Plattegrond</h1><br>
        <ul class="photo-gallery">
            <li *ngFor="let i of building.floorplans">

                    <img [src]="i.thumbnail" alt="">
                    <span *ngIf="i.caption">{{i.caption}}</span>

            </li>
        </ul>

        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><em>De informatie in deze brochure is met de grootste zorg samengesteld. Echter bestaat altijd de mogelijkheid op afwijkingen en/of onjuistheden ten opzichte van de werkelijkheid. Zowel Muller Bedrijfs Onroerend Goed als de eigenaar zijn niet aansprakelijk voor mogelijke onjuistheden in de informatie.</em></p>
    </section>

    <div class="page-break" *ngIf="building.cadastralMap"></div>

    <section class="brochure__page" *ngIf="building.cadastralMap">
        <h1>Kadastrale kaart</h1><br>

        <img [src]="building.cadastralMap" />
    </section>

    <div class="page-break" *ngIf="building.goadPlan"></div>

    <section class="brochure__page" *ngIf="building.goadPlan">
        <h1>Goad plan</h1><br>
        <!-- Niet gevonden in database -->
        <img [src]="building.goadPlan" />
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><em>De goad plan is bedoeld als een impressie. Hieraan kunnen geen rechten worden ontleend. De situatie kan afwijken van de werkelijkheid. Muller Bedrijfs Onroerend Goed aanvaardt hierin geen enkele aansprakelijkheid.</em></p>
    </section>
</div>

<article class="teaser {{type}}" [class.set--unavailable]="data.unavailable">
    <figure class="teaser__crop {{type}}__crop" *ngIf="data.img">
        <img [src]="data.img + '?width=470'" alt="" />

        <div class="teaser__content__meta__item {{type}}__content__meta__item type--unavailable" *ngIf="data.unavailable">{{data.status.status === 'Huur' ? 'Verhuurd' : 'Verkocht'}}</div>
    </figure>

    <app-icon class="teaser__icon {{type}}__icon" [anchor]="data.icon" *ngIf="data.icon"></app-icon>

    <div class="teaser__content {{type}}__content">
        <header class="teaser__content__header {{type}}__content__header">
            <h1 class="teaser__content__title {{type}}__content__title">{{data.title}}</h1>
            <p class="{{type}}__content__text" *ngIf="data.text">{{data.text}}</p>
            <p class="{{type}}__content__text" *ngIf="data.referentialText">{{data.referentialText}}</p>
        </header>
        <ul class="teaser__content__meta {{type}}__content__meta">
            <li class="teaser__content__meta__item {{type}}__content__meta__item type--price" *ngIf="data.price && !data.inReferences">{{data.price}}</li>
            <li class="teaser__content__meta__item {{type}}__content__meta__item type--surfaceSize" *ngIf="data.surfaceSize && !data.inReferences">{{data.surfaceSize}}m2</li>
            <li class="teaser__content__meta__item {{type}}__content__meta__item type--{{setBuildClass(data.status.status)}}" *ngIf="data.status && !data.inReferences">
                <div>{{data.status.status}}</div>
            </li>
            <li *ngIf="data.types" class="teaser__content__meta__item {{type}}__content__meta__item type--types">{{data.types | stringJoin:'type':', '}}</li>
        </ul>
    </div>

    <a [routerLink]="data.routerLink" class="block-link teaser__block-link {{type}}__button" *ngIf="data.routerLink && !data.inReferences" [name]="data.title">{{data.title}}</a>

</article>

<form [formGroup]="filterForm" class="filter-bar">

  <section class="filter-bar__section">
    <div class="search-form type--small">
      <input type="search" id="q" formControlName="q" class="search-form__input" placeholder="Zoek op trefwoord">
      <button class="search-form__button button type--fill-blue type--icon" type="submit"><app-icon [anchor]="'icon-search'"></app-icon></button>

    </div>
  </section>

  <section class="filter-bar__section" formGroupName="places">
    <h3 class="filter-bar__section__title">Plaats</h3>

    <ul class="filter-bar__list">
      <li *ngFor="let item of filterForm.get('places').value | keyvalue">
        <input type="checkbox" [id]="item.key" [formControlName]="item.key" [value]="item.value">
        <label [for]="item.key">
          <app-icon [anchor]="'icon-check'"></app-icon>
          <span>{{item.key}}</span>
        </label>
      </li>
    </ul>
  </section>
  <section class="filter-bar__section" formGroupName="types">
    <h3 class="filter-bar__section__title">Type</h3>

    <ul class="filter-bar__list">
      <li *ngFor="let item of filterForm.get('types').value | keyvalue">
        <input type="checkbox" [id]="item.key" [formControlName]="item.key" [value]="item.value">
        <label [for]="item.key">
          <app-icon [anchor]="'icon-check'"></app-icon>
          <span>{{item.key}}</span>
        </label>
      </li>
    </ul>
  </section>
  <section class="filter-bar__section" formGroupName="status">
    <h3 class="filter-bar__section__title">Status</h3>
    <ul class="filter-bar__list">
      <li *ngFor="let item of filterForm.get('status').value | keyvalue">
        <input type="checkbox" [id]="item.key" [formControlName]="item.key" [value]="item.value">
        <label [for]="item.key">
          <app-icon [anchor]="'icon-check'"></app-icon>
          <span>{{item.key}}</span>
        </label>
      </li>
    </ul>
  </section>
</form>

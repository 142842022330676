<article class="portfolio columns container type--l" >
  <h1 class="is--hidden">{{'home-aanbod-title' | fromDictionary}}</h1>
  <header class="portfolio__header" *ngIf="isMobile">
      <h1 class="block__title portfolio__header__title">{{results.length ? results.length : 'Geen'}} {{results.length > 1 ? 'objecten' : 'object'}} gevonden</h1>

       <button class="button type--stroke-light-grey type--icon-right" [class.is--active]="showMobileFilters" (click)="showMobileFilters = !showMobileFilters">
          Filteren
          <app-icon [anchor]="'icon-cross'"></app-icon>
        </button>

    </header>
  <aside class="portfolio__filters" [class.is--mobile-active]="showMobileFilters">
    <h1 class="is--hidden">Filter aanbod</h1>
    <app-filter-bar [filters]="filters" (filterChange)="updateOverview($event)"></app-filter-bar>
  </aside>
  <!-- 4 uitgelichte objecten -->
  <section class="portfolio__results">
    <header class="portfolio__results__header">
      <h1 class="block__title">{{results.length ? results.length : 'Geen'}} {{results.length > 1 ? 'objecten' : 'object'}} gevonden</h1>
      <div class="portfolio__results__options">
        <div class="portfolio__results__sort select" >
          <app-icon [anchor]="'icon-chevron-down'"></app-icon>
          <select #sortOptions [(ngModel)]="sortOption">
            <option disabled>Sorteer op</option>
            <option *ngFor="let item of sort" [value]="item.value">{{item.title}}</option>
          </select>
        </div>
        <ul class="portfolio__results__layout button-group">
          <li class="button-group__item" *ngFor="let btn of views">
            <button class="button type--stroke-light-grey type--icon-left" [class.is--active]="btn.active" (click)="setView(btn)">
              <app-icon [anchor]="'icon-'+btn.value+'-view'"></app-icon>
              {{btn.title}}
            </button>
          </li>
        </ul>
      </div>
    </header>
    <ul class="portfolio__results__list {{layout==='card' ? 'card-list ' : 'grid-list'}}" >
      <li class="grid__item" *ngFor="let obj of results | sortBy:[sortOptions.value]" [class.set--unavailable]="obj.unavailable">
        <app-teaser [type]="'teaser-'+layout" [data]="obj.ToTeaser()">
        </app-teaser>
      </li>
    </ul>
  </section>
</article>

<div class="building-gallery">
  <ul
    class="building-gallery__list set--total-{{ building.gallery.length }}"
    [attr.data-total]="setIndex(building.gallery)"
    [ngStyle]="{ width: setWidth() + 'vw' }"
  >
    <li
      class="building-gallery__list__item"
      *ngFor="let item of building.gallery; let i = index"
      (click)="openModal(i)"
    >
      <figure class="building-gallery__crop">
        <img [src]="item.thumbnail + '?width=1020&format=jpg'" class="" />
        <app-icon [anchor]="'icon-zoom-photo'"></app-icon>
      </figure>
    </li>
  </ul>
</div>

import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  PLATFORM_ID,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Building } from "../../interfaces/building";
import { isPlatformBrowser, DOCUMENT } from "@angular/common";
import { NavigationService } from "../../../core/services/navigation.service";
import { appSettings } from "../../../core/classes/globals";

@Component({
  selector: "app-brochure",
  templateUrl: "./brochure.component.html",
  styleUrls: ["./brochure.component.scss"],
})
export class BrochureComponent implements OnInit, OnDestroy {
  building: Building;
  rentalTermsOrder = [
    "acceptance",
    "rentalPriceSpecification",
    "btw",
    "serviceCosts",
    "promotionalContribution",
    "rentalPayment",
    "rentalPeriod",
    "rentalAgreement",
    "rentalPriceIndexing",
    "guaranty",
    "noticePeriod",
    "reserve",
    "stateOfDelivery",
  ];

  purchaseTermsOrder = [
    "acceptance",
    "stateOfDelivery",
    "purchasePriceSpecification",
    "btw",
    "guaranty",
    "serviceCosts",
    "noticePeriod",
    "reserve",
    "homeOwnersAssociationContribution",
    "ozb",
  ];
  imageMap: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private navService: NavigationService,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(DOCUMENT) private document: any
  ) {
    this.navService.lite.next(true);
  }

  async ngOnInit() {
    this.building = this.route.snapshot.data.building;

    if (
      this.building.street &&
      this.building.houseNumber &&
      this.building.place
    ) {
      const markerurl = appSettings.productionServer;
      this.imageMap = `https://maps.googleapis.com/maps/api/staticmap?center=
          ${this.building.street}+
          ${this.building.houseNumber},
          ${this.building.place}&
          &markers=icon:${markerurl}/assets/gfx/marker-maps.png%7C${this.building.street}+${this.building.houseNumber}+${this.building.place}
          &zoom=16&size=800x400&maptype=roadmap&key=AIzaSyC-MnVluH02PwHAp0VJIyBoM2eFX3e4Sq4`;
    }

    console.log(this.building);
  }

  ngOnDestroy() {
    this.navService.lite.next(false);
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      // this.document.querySelector('body').classList.add('set--calendar');
    }
  }
}

import { SafePipe } from "./pipes/safe.pipe";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppModule } from "../app.module";
import { StringJoinPipe } from "./pipes/stringjoin.pipe";
import { UrlRewritePipe } from "./pipes/urlrewrite.pipe";
import { LoaderIndicatorComponent } from "./components/loading-indicator/loading-indicator.component";
import { HeaderComponent } from "./components/header/header.component";
import { TopbarComponent } from "./components/topbar/topbar.component";
import { IconComponent } from "./components/icon/icon.component";
import { ScrollToDirective } from "./directives/scroll-to.directive";
import { StripHtmlPipe } from "./pipes/strip-html.pipe";
import { TruncatePipe } from "./pipes/truncate.pipe";
import { SearchPipe } from "./pipes/search.pipe";
import { SortByPipe } from "./pipes/sort-by.pipe";
import { SortByLengthPipe } from "./pipes/sort-by-length.pipe";
import { FooterComponent } from "./components/footer/footer.component";
import { YoutubeComponent } from "./components/youtube/youtube.component";
import { Html5VideoComponent } from "./components/html5video/html5video.component";
import { TeaserComponent } from "./components/teaser/teaser.component";
import { InViewportDirective } from "./directives/in-view.directive";
import { CountDirective } from "./directives/count.directive";
import { FromDictionaryPipe } from "./pipes/from-dictionary.pipe";
import { ContactCardComponent } from "./components/contact-card/contact-card.component";
import { MapComponent } from "./components/map/map.component";
import { GoogleMapsModule } from "@angular/google-maps";

const moduleExports = [
  StringJoinPipe,
  UrlRewritePipe,
  StripHtmlPipe,
  TruncatePipe,
  SortByPipe,
  SearchPipe,
  LoaderIndicatorComponent,
  HeaderComponent,
  TopbarComponent,
  IconComponent,
  ScrollToDirective,
  SortByLengthPipe,
  FooterComponent,
  YoutubeComponent,
  Html5VideoComponent,
  TeaserComponent,
  InViewportDirective,
  CountDirective,
  FromDictionaryPipe,
  ContactCardComponent,
  MapComponent,
  SafePipe,
];

@NgModule({
  imports: [CommonModule, RouterModule, GoogleMapsModule],
  declarations: [...moduleExports],
  exports: [...moduleExports],
  providers: [],
})
export class CoreModule {}

<header class="header {{cssClass}}">

    <figure class="header__crop {{cssClass}}-secondary" [class.set--overlay]="title" [class.no--mask]="hideHeaderText" [class.no--image]="!bgImage">

      <img class="header__crop__image" [src]="bgImage+'?maxwidth=1800&format=jpg'" *ngIf="!videoBg" />

        <app-htmlvideo class="header__crop__video" *ngIf="videoBg" [posterSrc]="bgImage+'?maxwidth=1800&format=jpg'" [videoSrc]="videoBg">

        </app-htmlvideo>

    </figure>

    <div class="header__content" [class.overlay]="!hideHeaderText" *ngIf="!hideHeaderText">
          <h1 class="h1 header__content__title">
            {{title}}
          </h1>
        </div>

</header>

import {
  Directive,
  Output,
  EventEmitter,
  AfterViewInit,
  ElementRef,
  Input,
} from "@angular/core";

@Directive({
  selector: "[appInViewport]",
})
export class InViewportDirective implements AfterViewInit {
  @Output() appInViewport = new EventEmitter<any>(null);
  @Input() delay = 500;
  @Input() once = true;
  @Input() threshold = 0;

  private _isIntersecting = false;

  constructor(private element: ElementRef) {}

  ngAfterViewInit() {
    if (
      typeof window !== undefined &&
      window["IntersectionObserver"] !== undefined
    ) {
      setTimeout(() => {
        const callback = (e) => {
          if (e[0].isIntersecting) {
            this._isIntersecting = true;
            this.appInViewport.emit(this._isIntersecting);
            if (this.once) {
              observer.disconnect();
            }
          } else if (this._isIntersecting && !e[0].isIntersecting) {
            this._isIntersecting = false;
            this.appInViewport.emit(this._isIntersecting);
          }
        };
        const observer = new IntersectionObserver(callback, {
          threshold: [this.threshold],
        });
        observer.observe(this.element.nativeElement);
      }, this.delay);
    }
  }
}

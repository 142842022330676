import { ContentPage } from './../../interfaces/content-page';
import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-content-page',
  templateUrl: './content-page.component.html',
})
export class ContentPageComponent implements OnInit, OnDestroy {
  page: ContentPage;
  target: string;

  // tslint:disable-next-line:max-line-length
  contactUri = 'https://www.google.nl/maps/dir//Eekwal+3,+8011+LA+Zwolle/@52.5108761,6.0865906,17z/data=!4m17!1m7!3m6!1s0x47c7df2e9217d69d:0x1f4ea459015f888e!2sEekwal+3,+8011+LA+Zwolle!3b1!8m2!3d52.5108761!4d6.0887793!4m8!1m0!1m5!1m1!1s0x47c7df2e9217d69d:0x1f4ea459015f888e!2m2!1d6.0887793!2d52.5108761!3e1';

  constructor(
    private route: ActivatedRoute,
    private metaTitle: Title,
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(DOCUMENT) private document: any
  ) { }

  ngOnInit() {
    this.route.data.subscribe(a => {
      this.page = a.contentPage;
      this.metaTitle.setTitle(this.page.title);
      this.target = a.contentPage ? a.contentPage.target : ''; // to tafrget with custom css
      this.meta.addTags([
        {
          property: 'og:title',
          content: this.page.title,
        },
        {
          property: 'og:description',
          content: this.page.bodyText,
        },
        {
          property: 'og:image',
          content: this.page.headerImage,
        },
      ]);
    });
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);

    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);

    }
  }
}

import {
  Directive,
  Input,
  AfterViewInit,
  ElementRef,
  OnChanges,
} from "@angular/core";
import { DecimalPipe } from "@angular/common";

@Directive({
  selector: "[appCount]",
})
export class CountDirective implements AfterViewInit, OnChanges {
  @Input() appCount: number;
  @Input() countStart = false;
  @Input() decimals = 0;
  started: false;
  framerate = 66;
  time = 1500;
  steps = this.time / this.framerate;

  constructor(private element: ElementRef, private decimalPipe: DecimalPipe) {}

  ngAfterViewInit() {
    this.reset();
  }

  ngOnChanges(changes) {
    changes &&
    changes["countStart"] &&
    changes["countStart"].currentValue === true
      ? this.start()
      : this.reset();
  }

  reset() {
    this.element.nativeElement.innerHTML = 0;
  }

  start() {
    for (let i = 0; i <= this.steps; i++) {
      const delay = i * this.framerate;
      const factor = delay / this.time;
      // tslint:disable-next-line:max-line-length
      const formatedNumber = this.decimalPipe
        .transform(
          parseFloat((factor * this.appCount) as any).toFixed(this.decimals)
        )
        .replace(".", ",");

      setTimeout(
        () => (this.element.nativeElement.innerHTML = formatedNumber),
        delay
      );
    }
    setTimeout(
      () =>
        (this.element.nativeElement.innerHTML = this.decimalPipe
          .transform(parseFloat(this.appCount as any))
          .replace(".", ",")),
      this.time
    );
  }
}

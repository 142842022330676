import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SearchSettings } from '../../interfaces/search-settings';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
})
export class FilterBarComponent implements OnInit {
  @Input() filters: SearchSettings;
  @Output() filterChange: EventEmitter<any> = new EventEmitter<any>();

  filterForm: FormGroup = this.fb.group({
    q: this.fb.control(''),
    places: this.fb.group({}),
    types: this.fb.group({}),
    status: this.fb.group({}),
  });
  constructor(private route: ActivatedRoute, private fb: FormBuilder) {}

  ngOnInit() {
    this.filterForm.get('q').setValue(this.route.snapshot.queryParams['q']);

    this.filters.places.forEach(p => {
      const selectedValues = this.route.snapshot.queryParams['places'];
      const isActive = Array.isArray(selectedValues)
        ? selectedValues.indexOf(p) >= 0
        : selectedValues === p;

      (this.filterForm.get('places') as FormGroup).addControl(
        p,
        new FormControl(isActive)
      );
    });

    this.filters.status.forEach(p => {
      const selectedValues = this.route.snapshot.queryParams['status'];
      const isActive = Array.isArray(selectedValues)
        ? selectedValues.indexOf(p) >= 0
        : selectedValues === p;

      (this.filterForm.get('status') as FormGroup).addControl(
        p,
        new FormControl(isActive)
      );
    });

    this.filters.types.forEach(p => {
      const selectedValues = this.route.snapshot.queryParams['types'];
      const isActive = Array.isArray(selectedValues)
        ? selectedValues.indexOf(p) >= 0
        : selectedValues === p;

      (this.filterForm.get('types') as FormGroup).addControl(
        p,
        new FormControl(isActive)
      );
    });

    this.filterForm.valueChanges.subscribe(value => this.filterChange.emit(value));
  }
}

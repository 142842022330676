import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Building } from '../interfaces/building';
import { BuildingSummary } from '../classes/building-summary';
import { delay, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PortfolioService  {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    if (route.params.id) {
      return this.get(route.params.id).pipe(delay(500));
    }

    return this.summary(500); // Question @Thomas wat doet dit??
  }

  search() {}

  get(id: number): Observable<Building> {
    return this.http.get<Building>(`${this.BASE_URL}api/portfolio/${id}`);
  }

  related(id: number): Observable<BuildingSummary[]> {
    return this.http
      .get<BuildingSummary[]>(`${this.BASE_URL}api/portfolio/${id}/related`)
      .pipe(map(a => a.map(b => BuildingSummary.FromJSON(b))));
  }

  summary(take: number = 4): Observable<BuildingSummary[]> {
    return this.http
      .get<BuildingSummary[]>(
        `${this.BASE_URL}api/portfolio/summary?take=${take}`
      )
      .pipe(map(a => a.map(b => BuildingSummary.FromJSON(b))));
  }
}

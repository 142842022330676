import { Injectable, Optional, Inject } from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ContentPage } from '../../content/interfaces/content-page';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  lite: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    router: Router,
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
    ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loading.next(true);
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel
      ) {
        this.loading.next(false);
      }
    });
  }

  getMenuItems() {
    return this.http.get<ContentPage[]>(`${this.BASE_URL}api/content/menu`);
  }
}

import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  Optional,
  HostListener,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Title, Meta } from "@angular/platform-browser";
import { LanguageService } from "../../../core/services/language.service";
import { BuildingSummary } from "../../classes/building-summary";
import { SearchSettings } from "../../interfaces/search-settings";
import { FromDictionaryPipe } from "../../../core/pipes/from-dictionary.pipe";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { Utility } from "../../../core/classes/utility";
import { FormBuilder } from "@angular/forms";
import { SearchService } from "../../services/search.service";
import { throttle } from "rxjs/operators";
import { interval } from "rxjs";

@Component({
  selector: "app-references",
  templateUrl: "./references.component.html",
})
export class ReferencesComponent implements OnInit, OnDestroy {
  results: BuildingSummary[];
  filters: SearchSettings;
  dictionary = new FromDictionaryPipe(this.langService);

  isMobile = false;
  showMobileFilters = false;

  sort = [
    {
      value: "id",
      title: "Relevantie",
    },
    {
      value: "street",
      title: "Titel A-Z",
    },
    {
      value: "-street",
      title: "Titel Z-A",
    },
  ];

  sortOption: any = "id";

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private searchService: SearchService,
    private metaTitle: Title,
    private meta: Meta,
    private langService: LanguageService,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(DOCUMENT) private document: any,
    @Optional() @Inject("BASE_URL") private BASE_URL: string
  ) {}

  async ngOnInit() {
    this.metaTitle.setTitle(this.dictionary.transform("home_title"));

    this.meta.addTags([
      {
        property: "og:title",
        content: `${this.dictionary.transform("home_title")}`,
      },
      {
        property: "og:description",
        // tslint:disable-next-line:max-line-length
        content: `Muller Bedrijfsonroerendgoed is een gespecialiseerd bedrijfsmakelaarskantoor met ruime ervaring en gedegen kennis van de markt in Zwolle en omgeving.`,
      },
      {
        property: "og:image",
        content: `${this.BASE_URL}assets/images/header-zwolle.jpg`,
      },
    ]);

    this.route.data.subscribe((a) => {
      this.results = a.results;
      this.filters = a.settings;
    });

    this.route.queryParams
      .pipe(throttle((val) => interval(200)))
      .subscribe((a) => {
        this.searchService
          .find(a.q, a.places, a.types, a.status, true)
          .toPromise()
          .then((results) => (this.results = results));
      });

    // check if mobile
    this.checkMobile();
  }

  checkMobile() {
    if (isPlatformBrowser(this.platformId)) {
      if (Utility.getBreakpoint() === "breakpoint-mobile") {
        this.isMobile = true;
        // this.layout = 'list';
      }
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.checkMobile();
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      this.document.querySelector("body").classList.add("set--calendar");
    }
  }

  updateOverview(filters: any) {
    let places = Object.keys(filters.places).filter((a) => filters.places[a]);
    let types = Object.keys(filters.types).filter((a) => filters.types[a]);
    let status = Object.keys(filters.status).filter((a) => filters.status[a]);

    places = places.length > 0 ? places : null;
    types = types.length > 0 ? types : null;
    status = status.length > 0 ? status : null;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { q: filters.q, places, types, status },
      queryParamsHandling: "merge",
    });
  }
}

<article class="content" [attr.data-target]="(target || 'none') | lowercase">

  <div class="container type--l"  *ngIf="page.headerImage">
    <app-header class="block content__header"
      [bgImage]="page.headerImage"
      *ngIf="page.headerImage"
      [title]="''">
    </app-header>
  </div>

  <!-- Alles over MullerBOG -->
  <div class="container type--l type--content" [class.set--top-margin]="!page.headerImage">
    <section class="block content__about">
      <h1 class="block__title">{{page.title}}</h1>
      <div class="content__block" >
        <div class="content__block__text" [innerHTML]="page.bodyText | safe: 'html'"></div>
      </div>
    </section>

    <!-- Toelichting over waar MullerBOG naar zoekt -->
    <section class="block content__searching  bg--light-grey" *ngIf="page.blockTitle && page.blockText">
      <h1 class="block__title">{{page.blockTitle}}</h1>
      <div class="content__block" [innerHTML]="page.blockText"></div>
    </section>

    <!-- Contact kaart met adresgegevens en dergelijke -->
    <section class="block content__contact bg--pink">
        <h1 class="content__contact__title">Neem contact op</h1>
        <div class="content__block type--l">
          <div class="content__contact__text">
            <ul class="content__list__split">
              <li class="content__list__item"><a href="tel:0031384237111">038 423 7111</a></li>
              <li class="content__list__item">www.mullerbog.nl</li>
              <li class="content__list__item"><a href="mailto:info@mullerbog.nl?subject=Neem contact met mij op" class="" >info@mullerbog.nl</a></li>
              <li class="content__list__item"><strong>BTW</strong> NL 8529.47.902 B.01</li>
              <li class="content__list__item"><strong>KVK</strong> 58253483</li>
            </ul>

            <dl class="content__def-list">
              <dt class="content__title">Bezoekadres</dt>
                <dd class="content__desc">
                  <a href="{{contactUri}}" target="_blank">{{ 'adres' | fromDictionary }}</a>
                  <br>
                  Bezoek volgens afspraak, ook buiten kantooruren.
                </dd>
              <dt class="content__title">Postadres</dt>
                <dd class="content__desc">
                  {{ 'adres' | fromDictionary }}
                </dd>
            </dl>
          </div>
          <figure class="content__contact__crop">
            <img [src]="('content-page-contact-image' | fromDictionary) + '?width=300 &format=png'" alt="" />
          </figure>
        </div>
    </section>
  </div>
  <!-- <pre>{{page | json}}</pre> -->
</article>

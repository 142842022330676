import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Building } from '../interfaces/building';
import { BuildingSummary } from '../classes/building-summary';
import { delay, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SearchService  {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const isRef = route.data.isRef === true;
    return this.find(
      route.queryParams['q'],
      route.queryParams['places'],
      route.queryParams['types'],
      route.queryParams['status'],
      isRef
    );
  }

  search() {}

  get(id: number): Observable<Building> {
    return this.http.get<Building>(`${this.BASE_URL}api/portfolio/${id}`);
  }

  find(
    q: string = '',
    places: string[] = [],
    types: string[] = [],
    status: string[] = [],
    isRef: boolean = false,
    take: number = 500
  ): Observable<BuildingSummary[]> {
    const body = {
      q,
      places: Array.isArray(places) ? [...places] : [places],
      types: Array.isArray(types) ? [...types] : [types],
      status: Array.isArray(status) ? [...status] : [status],
    };

    // console.log(body);

    return this.http
      .post<BuildingSummary[]>(
        `${this.BASE_URL}api/portfolio/find?take=${take}&references=${isRef}`,
        body
      )
      .pipe(map(a => a.map(b => BuildingSummary.FromJSON(b))));
  }
}

import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { LanguageService } from './core/services/language.service';
import { NavigationService } from './core/services/navigation.service';
import { ContentPage } from './content/interfaces/content-page';
import { Router, NavigationEnd } from '@angular/router';

import * as svg4everybody from 'svg4everybody';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'app';
  loading = true;
  pages: ContentPage[];
  hideNav: boolean;

  private _footerItems: ContentPage[];
  private _menuItems: ContentPage[];

  get footerItems(): ContentPage[] {
    return this._footerItems;
  }

  get menuItems(): ContentPage[] {
    return this._menuItems;
  }

  constructor(
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId,
    private langService: LanguageService,
    private navService: NavigationService,
    private router: Router,
  ) {
    this.router.events.subscribe(event => {
      if (isPlatformBrowser(this.platformId) && event instanceof NavigationEnd) {
        (<any>window).gtag('config', 'UA-711816-23', {'page_path': event.urlAfterRedirects, 'anonymize_ip': true });
      }
    });
    this.langService.init();
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.document.querySelector('body').classList.add('set--in');


      setTimeout(() => {
        this.loading = false;
        svg4everybody();
      }, 1000);
    } else {
      this.loading = false;
    }

    this.navService.loading.subscribe(a => {
      this.loading = a;
    });

    this.navService.lite.subscribe(a => {
      this.hideNav = a;
    });

    this.navService.getMenuItems().subscribe(a => {
      this.pages = a;
      this._footerItems = a.filter(b => b.inFooter);
      this._menuItems = a.filter(b => b.inMenu);
    });
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PortfolioComponent } from './containers/portfolio/portfolio.component';
import { BuildingComponent } from './containers/building/building.component';
import { PortfolioService } from './services/portfolio.service';
import { SearchService } from './services/search.service';
import { SearchSettingsService } from './services/search-settings.service';
import { BrochureComponent } from './containers/brochure/brochure.component';
import { ReferencesComponent } from './containers/references/references.component';
import { ReferencesService } from './services/references.service';

const routes: Routes = [
  {
    path: '',
    component: PortfolioComponent,
    pathMatch: 'full',
    resolve: {
      results: SearchService,
      settings: SearchSettingsService,
    },
  },
  {
    path: 'referenties',
    component: ReferencesComponent,
    pathMatch: 'full',
    data: {
      isRef: true,
    },
    resolve: {
      results: SearchService,
      settings: SearchSettingsService,
    },
  },
  {
    path: ':id/:target/brochure',
    component: BrochureComponent,
    resolve: {
      building: PortfolioService,
    },
  },
  {
    path: ':id/:target',
    component: BuildingComponent,
    resolve: {
      building: PortfolioService,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PortfolioRoutingModule {}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
})
export class ContactCardComponent implements OnInit {
  @Input() data = {
    emailSubject: 'Neem contact met mij op'
  };

  buttonTelToggle = false; // var needed for toggle class phonenumber or titel
  constructor() { }

  ngOnInit() {
  }
}

import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SearchSettings } from '../interfaces/search-settings';

@Injectable({
  providedIn: 'root',
})
export class SearchSettingsService  {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.get(route.data['isRef']);
  }

  search() {}

  get(references = false): Observable<SearchSettings> {
    if (references) {
      return this.http.get<SearchSettings>(
        `${this.BASE_URL}api/portfolio/settings/references`
      );
    }

    return this.http.get<SearchSettings>(
      `${this.BASE_URL}api/portfolio/settings/portfolio`
    );
  }
}

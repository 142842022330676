import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Language } from '../types/language.type';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  dictionary: any;
  private currentLanguage: Language = 'nl';

  constructor(
    private Http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  loadDictionary() {
    this.Http.get(`${this.BASE_URL}api/dictionary`)
      .toPromise()
      .then(a => (this.dictionary = a));
  }

  getLanguage() {
    return this.currentLanguage;
  }

  init() {
    if (!this.BASE_URL) {
      this.currentLanguage = 'nl';
    } else if (this.BASE_URL.indexOf('.nl/') >= 0) {
      this.currentLanguage = 'nl';
    } else if (this.BASE_URL.indexOf('.com/') >= 0) {
      this.currentLanguage = 'en';
    } else if (this.BASE_URL.indexOf('.de/') >= 0) {
      this.currentLanguage = 'de';
    } else if (this.BASE_URL.indexOf('.fr/') >= 0) {
      this.currentLanguage = 'fr';
    } else if (this.BASE_URL.indexOf('.cn/') >= 0) {
      this.currentLanguage = 'cn';
    } else {
      this.currentLanguage = 'nl';
    }

    this.loadDictionary();
  }
}

<section class="block building-features">
    <h1 class="block__title building-features__title">Kenmerken</h1>


    <dl class="building-features__list">
        <dt class="building-features__list__title">Algemeen</dt>
        <dd class="building-features__list__definition" *ngIf="data.rentPrice"><em>Huurprijs</em> <span>{{data.rentPrice}}</span></dd>
        <dd class="building-features__list__definition" *ngIf="data.buyPrice"><em>Koopsom</em> <span>{{data.buyPrice}}</span></dd>
        <dd class="building-features__list__definition" *ngIf="data.status.status"><em>Status</em> <span>{{data.status.status}}</span></dd>
        <dd class="building-features__list__definition" *ngIf="data.surfaceSize"><em>Oppervlakte</em> <span>{{data.surfaceSize}} m2</span></dd>
        <dd class="building-features__list__definition" *ngIf="data.frontWidth"><em>Frontbreedte</em> <span>{{data.frontWidth}}</span></dd>

        <dd class="building-features__list__definition" *ngIf="data.types.length"><em>Type object</em> <div><span>{{data.types | stringJoin: 'type'}}</span></div></dd>
        <dd class="building-features__list__definition" *ngIf="data.buildYear"><em>Bouwjaar</em> <span>{{data.buildYear}}</span></dd>
        <dd class="building-features__list__definition" *ngIf="data.street"><em>Adres</em> <div><span *ngIf="data.street">{{data.street}}</span> <span *ngIf="data.houseNumber">{{data.houseNumber}}</span> </div></dd>
        <dd class="building-features__list__definition" *ngIf="data.place"><em>Plaats</em> <div><span *ngIf="data.postalCode">{{data.postalCode}}</span> <span *ngIf="data.place">{{data.place}}</span></div></dd>
        <dd class="building-features__list__definition" *ngIf="data.cadastralData"><em>Kadastraal</em> <span>{{data.cadastralData}}</span></dd>
    </dl>
</section>

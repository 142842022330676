<article class="contact-card ">

    <h1 class="contact-card__title">
      {{'contact-card-title' | fromDictionary}}
    </h1>
    <div class="contact-card__text">
      {{'contact-card-text' | fromDictionary}}
    </div>
    <ul class="contact-card__meta">
      <li>{{'contact-card-meta' | fromDictionary}}</li>
    </ul>

    <figure class="contact-card__crop">
      <img [src]="('contact-card-image' | fromDictionary) + '?width=400&format=png'" alt="" />
    </figure>

  <div class="contact-card__buttons">
    <a href="mailto:info@mullerbog.nl?subject={{data.emailSubject}}" class="button type--fill-blue" >Stuur bericht</a>
    <a href="tel:0031384237111" class="call--button button type--fill-pink" [class.is--active]="buttonTelToggle" (click)="buttonTelToggle = !buttonTelToggle" >

      <span [attr.data-hover]="'038 423 7111'"> Bel ons</span>
    </a>
  </div>

</article>

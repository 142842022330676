import { Component, OnInit, Input } from '@angular/core';
import { TeaserData } from '../../interfaces/teaser-data';

@Component({
  selector: 'app-teaser',
  templateUrl: 'teaser.component.html'
})
export class TeaserComponent implements OnInit {
  @Input() data: TeaserData;
  @Input() type = 'block';
  ngOnInit() { }

  setBuildClass(value) {
    const string = value
      .replace(/\//g, '')
      .toLowerCase();

    return string;
  }
}

<article class="building">

  <header class="text-center building__header ">
      <h1 class="block__title building__header__title">{{building.street}}
        <span *ngIf="building.houseNumber">{{building.houseNumber}}</span>
        <span *ngIf="building.place" >, {{building.place}}</span>
      </h1>
      <ul class="building__header__meta">
        <li class="building__header__meta__item" *ngIf="building.rentPrice">{{building.rentPrice}}</li>
        <li class="building__header__meta__item" *ngIf="building.buyPrice">{{building.buyPrice}}</li>
        <li class="building__header__meta__item" *ngIf="building.types.length"><span *ngFor="let item of building.types">{{item.type}} </span></li>
        <li class="building__header__meta__item" *ngIf="building.status">{{building.status.status}}</li>
        <li class="building__header__meta__item" *ngIf="building.surfaceSize">{{building.surfaceSize}} m2</li>
      </ul>

  </header>


    <app-building-gallery class="block ccontainer type--l building__gallery" [building]="building" *ngIf="isPlatformBrowser"></app-building-gallery>


  <div class="container type--l type--content">
    <div class="building__buttons">
      <button type="button" onclick=""class="button type--fill-pink" (click)="scroll(contact)">Interesse?</button>
      <a *ngIf="!building.brochureUrl" target="_blank" [href]="currentUri+'.pdf'"class="button type--stroke-pink">Download brochure</a>
      <a *ngIf="building.brochureUrl" target="_blank" [href]="building.brochureUrl"class="button type--stroke-pink">Download brochure</a>
    </div>

    <app-building-features
      [data]="building">
    </app-building-features>

    <section class="block building__description" *ngIf="building.description">
      <h1 class="block__title">Omschrijving</h1>

      <div class="content__text building__description__text" [innerHTML]="building.description"></div>
    </section>

    <section class="block building__map" *ngIf="isPlatformBrowser && building.location">
        <h1 class="block__title building__map__title">Locatie</h1>
        <app-map
          class="map set--min"
          [zoom]="15"
          [latitude]="building.location.latitude"
          [longitude]="building.location.longitude"
          [options]="{
            zoomControl: true,
            streetViewControl: true}"
          [locations]="[building]">
        </app-map>
    </section>

    <section class="block set--min set--padding bg--light-grey building__summary">
      <ul class="building__summary__list">
        <li class="building__summary__list__item" *ngIf="building.reachability">
          <h1 class="block__title">Bereikbaarheid</h1>
          <div class="building__summary__list__text" [innerHTML]="building.reachability"></div>
        </li>
        <li class="building__summary__list__item" *ngIf="building.parking">
          <h1 class="block__title">Parkeren</h1>
          <div class="building__summary__list__text" [innerHTML]="building.parking"></div>
        </li>
        <li class="building__summary__list__item" *ngIf="building.summary">
            <h1 class="block__title">Samenvatting</h1>
            <div class="building__summary__list__text" [innerHTML]="building.summary"></div>
          </li>
          <li class="building__summary__list__item" *ngIf="building.facilities">
            <h1 class="block__title">Voorzieningen</h1>
            <div class="building__summary__list__text" [innerHTML]="building.facilities"></div>
          </li>
          <li class="building__summary__list__item" *ngIf="building.destination">
            <h1 class="block__title">Bestemming</h1>
            <div class="building__summary__list__text" [innerHTML]="building.destination"></div>
          </li>
      </ul>
    </section>

    <section class="block building__requirements" *ngIf="getTotalRentalTerms.length > 0">
      <h1 class="block__title">Voorwaarden<ng-container *ngIf="building.status.id === 3">&nbsp;(Huur)</ng-container></h1>
      <ul class="building__requirements__list" [class.set--active]="showAllRentalTerms">

        <ng-container *ngFor="let key of rentalTermsOrder">
          <li class="building__requirements__list__item" *ngIf="building.rentalTerms [key]">
            <article class="requirement">
                <h1 class="requirement__title">{{ 'term-' + key | fromDictionary}}</h1>
                <div class="requirement__text" [innerHTML]="building.rentalTerms[key]">
                </div>
            </article>
          </li>
        </ng-container>


        <!--<ng-container *ngFor="let item of building.rentalTerms | keyvalue">
          <li class="building__requirements__list__item" *ngIf="item.value">
            <article class="requirement">
                <h1 class="requirement__title">{{ 'term-'+item.key | fromDictionary}}</h1>
                <div class="requirement__text">
                  {{item.value}}
                </div>
            </article>
          </li>
        </ng-container>-->
      </ul>
      <button type="button" *ngIf="getTotalRentalTerms.length > 6" (click)="showAllRentalTerms = !showAllRentalTerms" class="button type--icon-right type--transparent " [class.is--active]="showAllRentalTerms">
        {{!showAllRentalTerms ? 'Lees alle voorwaarden' : 'Verberg voorwaarden'}}
        <app-icon [anchor]="'icon-chevron-down'"></app-icon>
      </button>
    </section>

    <section class="block building__requirements" *ngIf="getTotalPurchaseTerms.length > 0">
      <h1 class="block__title">Voorwaarden<ng-container *ngIf="building.status.id === 3">&nbsp;(Koop)</ng-container></h1>
      <ul class="building__requirements__list" [class.set--active]="showAllPurchaseTerms">


      <!--<li class="building__requirements__list__item" *ngIf="item.value">
          <article class="requirement">
              <h1 class="requirement__title">{{ building.purchaseTerms}}</h1>
              <div class="requirement__text">
                {{item.value}}
              </div>
          </article>
        </li>-->

        <ng-container *ngFor="let key of purchaseTermsOrder">
          <li class="building__requirements__list__item" *ngIf="building.purchaseTerms [key]">
            <article class="requirement">
                <h1 class="requirement__title">{{ 'term-' + key | fromDictionary}}</h1>
                <div class="requirement__text" [innerHTML]="building.purchaseTerms [key]">
                </div>
            </article>
          </li>
        </ng-container>

      </ul>
      <button type="button" *ngIf="getTotalPurchaseTerms.length > 6" (click)="showAllPurchaseTerms = !showAllPurchaseTerms" class="button type--icon-right type--transparent " [class.is--active]="showAllPurchaseTerms">
        {{!showAllPurchaseTerms ? 'Lees alle voorwaarden' : 'Verberg voorwaarden'}}
        <app-icon [anchor]="'icon-chevron-down'"></app-icon>
      </button>
    </section>

    <div class="block set--min" #contact>
      <app-contact-card [data]="toMailSubject()" class="building__contact bg--light-grey"></app-contact-card>
    </div>
  </div>

  <section class="block bg--light-grey building__related" *ngIf="relatedObjects && relatedObjects.length > 0">

    <div class="container">
        <h1 class="block__title color--blue"><a class="block__title__link" [routerLink]="['/aanbod/']">{{'related-title' | fromDictionary}}</a></h1>
        <ul class="grid-1-4">
          <li class="grid__item" *ngFor="let obj of relatedObjects" [class.set--unavailable]="obj.unavailable">
            <app-teaser
            (click)="toTop()"
            [type]="isMobile ? 'teaser-list': 'teaser-card'"
            [data]="obj.ToTeaser()">
            </app-teaser>
          </li>
        </ul>
    </div>
  </section>

</article>

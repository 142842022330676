import { Component, OnInit, Input } from '@angular/core';
import { Building } from '../../interfaces/building';

@Component({
  selector: 'app-building-features',
  templateUrl: './building-features.component.html'
})
export class BuildingFeaturesComponent implements OnInit {
  @Input() data: Building;
  constructor() { }

  ngOnInit() {
  }

}

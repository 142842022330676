<ng-container *ngIf="apiLoaded | async">
  <google-map
    width="100%"
    height="100%"
    [options]="mapOptions"
    [zoom]="zoom"
    [center]="{ lat: toFloat(latitude), lng: toFloat(longitude) }"
  >
    <map-marker
      *ngFor="let location of plottableLocations"
      [position]="{
        lat: toFloat(location.location.latitude),
        lng: toFloat(location.location.longitude)
      }"
      [options]="markerOptions"
      #marker="mapMarker"
      (mapClick)="openInfoWindow(marker, location)"
    ></map-marker>
    <map-info-window
      #infoWindow
      [maxWidth]="300"
      placement="bottom"
      [shadow]="false"
      [maxHeight]="200"
      [closeWhenOthersOpen]="true"
    >
      <section class="map__info-window" *ngIf="selectedLocation">
        <figure class="map__info-window__crop">
          <img [src]="selectedLocation.mainImage + '?width=470'" />
        </figure>

        <div class="map__info-window__content">
          <h1 class="map__info-window__title">
            {{ selectedLocation.street }}
            <span *ngIf="selectedLocation.houseNumber">
              {{ selectedLocation.houseNumber }}</span
            >
          </h1>
          <div class="map__info-window__text">
            <div>
              <span *ngIf="selectedLocation.postalCode">
                {{ selectedLocation.postalCode }}</span
              >
              <span *ngIf="selectedLocation.place">
                {{ selectedLocation.place }}</span
              >
            </div>
            <span
              *ngIf="selectedLocation.status.status"
              class="type--{{ selectedLocation.status.status | lowercase }}"
              >{{ selectedLocation.status.status }}</span
            >
          </div>

          <button
            [routerLink]="[
              '/aanbod',
              selectedLocation.id,
              selectedLocation.target
            ]"
            class="button type--fill-pink"
          >
            Meer informatie
          </button>
        </div>

        <!-- <main
            class="map__info-window__description"
            *ngIf="location?.description"
            [innerHTML]="location.description | safe: 'html'">
          </main> -->

        <footer class="map__info-window__footer" *ngIf="selectedLocation?.url">
          <a [href]="selectedLocation.url" target="_blank" class="button"
            >Bezoek de website</a
          >
        </footer>
      </section>
    </map-info-window>
  </google-map>
</ng-container>

import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Inject,
  InjectionToken,
  PLATFORM_ID,
} from "@angular/core";
import { catchError, map, tap } from "rxjs/operators";
import { mapStyles } from "./map.style";
import { MapLocation } from "../../interfaces/map-location";
import { Observable, of } from "rxjs";
import { isPlatformBrowser } from "@angular/common";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { appSettings } from "../../classes/globals";
import { MapInfoWindow, MapMarker } from "@angular/google-maps";
import { Building } from "src/app/portfolio/interfaces/building";

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
})
export class MapComponent implements OnInit {
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;

  @Input() locations: MapLocation[];
  @Input() zoom = 9;
  @Input() set options(value) {
    this.mapOptions = {
      ...this.mapOptions,
      ...value,
    };
    console.log(this.mapOptions);
  }
  @Input() latitude = "52.512769";
  @Input() longitude = "6.092941";
  styles = mapStyles;

  apiLoaded: Observable<boolean> = of(false);

  mapOptions: google.maps.MapOptions = {
    mapTypeControl: false,
    fullscreenControl: false,
    zoomControl: false,
    streetViewControl: false,
    ...this.options,
    styles: [...mapStyles],
  };

  markerOptions: google.maps.MarkerOptions;

  get plottableLocations() {
    return this.locations?.filter((a) => a.location);
  }

  selectedLocation: Building;

  constructor(
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
    private http: HttpClient
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (window["google"]?.["maps"]) {
        this.apiLoaded = of(true);
        this.markerOptions = {
          icon: {
            url: "https://mullerbog.nl/assets/gfx/map-marker@2x.png",
            scaledSize: new google.maps.Size(50, 50),
          },
        };
      } else {
        this.apiLoaded = this.http
          .jsonp(
            `https://maps.googleapis.com/maps/api/js?key=${appSettings.googleMapApiKey}`,
            "callback"
          )
          .pipe(
            tap(() => {
              this.markerOptions = {
                icon: {
                  url: "https://mullerbog.nl/assets/gfx/map-marker@2x.png",
                  scaledSize: new google.maps.Size(50, 50),
                },
              };
            }),
            map(() => true),
            catchError((e) => {
              console.error("Google map not loaded", e);
              return of(false);
            })
          );
      }
    }
  }

  toFloat(num: string) {
    return Number.parseFloat(num);
  }

  openInfoWindow(marker, location) {
    this.selectedLocation = location;
    this.infoWindow.open(marker);
  }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { PortfolioRoutingModule } from "./portfolio-routing.module";
import { PortfolioComponent } from "./containers/portfolio/portfolio.component";
import { BuildingComponent } from "./containers/building/building.component";
import { BrochureComponent } from "./containers/brochure/brochure.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { FilterBarComponent } from "./components/filter-bar/filter-bar.component";
import { CoreModule } from "../core/core.module";
import { BuildingGalleryComponent } from "./components/building-gallery/building-gallery.component";
import { GalleryModule } from "@ks89/angular-modal-gallery";
import { BuildingFeaturesComponent } from "./components/building-features/building-features.component";
import { ReferencesComponent } from "./containers/references/references.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PortfolioRoutingModule,
    CoreModule,
    GalleryModule,
  ],
  declarations: [
    PortfolioComponent,
    BuildingComponent,
    FilterBarComponent,
    BrochureComponent,
    BuildingGalleryComponent,
    BuildingFeaturesComponent,
    ReferencesComponent,
  ],
})
export class PortfolioModule {}

import {
  Component,
  OnInit,
  Input,
  Renderer2,
  Inject,
  HostListener,
} from "@angular/core";
import { Utility } from "../../classes/utility";
import { ContentPage } from "../../../content/interfaces/content-page";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
})
export class TopbarComponent implements OnInit {
  @Input() loading = false;
  @Input() menuItems: ContentPage[];
  menuActive = false;
  buttonTelContent = {
    default: "Bel ons",
    tel: "038 423 7111",
  };
  buttonTelToggle = false;
  navIsFixed = false;

  constructor(
    @Inject(DOCUMENT) private document,

    private renderer: Renderer2
  ) {}

  ngOnInit() {}

  toggle() {
    // check if mobile
    if (Utility.getBreakpoint() === "breakpoint-mobile") {
      this.menuActive = !this.menuActive;
      this.updateRoot();
    }
  }

  updateRoot() {
    this.menuActive
      ? this.renderer.addClass(this.document.body, "main-nav--active")
      : this.renderer.removeClass(this.document.body, "main-nav--active");
  }

  tel() {
    this.buttonTelToggle = !this.buttonTelToggle;
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    const number =
      this.document.pageYOffset ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0;
    if (number > 150) {
      this.navIsFixed = true;
    } else if (this.navIsFixed && number < 10) {
      this.navIsFixed = false;
    }
  }
}

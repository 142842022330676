import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ContentPage } from '../interfaces/content-page';

@Injectable({
  providedIn: 'root',
})
export class ContentService  {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    if (route.parent.params['page']) {
      return this.get(route.parent.params['page']);
    }
  }

  search() {}

  get(target: string): Observable<ContentPage> {
    return this.http.get<ContentPage>(`${this.BASE_URL}api/content/${target}`);
  }
}

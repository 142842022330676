/*
 *      Example use
 *      Basic Array of single type: *ngFor="let todo of todoService.todos | orderBy : '-'"
 *      Multidimensional Array Sort on single column: *ngFor="let todo of todoService.todos | orderBy : ['-status']"
 *      Multidimensional Array Sort on multiple columns: *ngFor="let todo of todoService.todos | orderBy : ['status', '-title']"
 */

import { Pipe, PipeTransform, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Pipe({ name: 'sortByLength' })
export class SortByLengthPipe implements PipeTransform {
  constructor(@Inject(PLATFORM_ID) private platformId) {}

  public transform(arr: Object[], key: string): any {
    if (!isPlatformBrowser(this.platformId)) {
      return arr;
    }

    if (
      !arr ||
      !Array.isArray(arr) ||
      !arr.every(obj => Array.isArray(obj[key]))
    ) {
      return arr;
    } else {
      return arr.sort((a, b) => b[key].length - a[key].length);
    }
  }
}

import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  OnDestroy,
  HostListener,
  Optional,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchSettings } from '../../interfaces/search-settings';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { SearchService } from '../../services/search.service';
import { throttle } from 'rxjs/operators';
import { interval } from 'rxjs';
import { BuildingSummary } from '../../classes/building-summary';
import { Title, Meta } from '@angular/platform-browser';
import { LanguageService } from '../../../core/services/language.service';
import { FromDictionaryPipe } from '../../../core/pipes/from-dictionary.pipe';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Utility } from '../../../core/classes/utility';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
})
export class PortfolioComponent implements OnInit, OnDestroy {
  results: BuildingSummary[];
  filters: SearchSettings;
  dictionary = new FromDictionaryPipe(this.langService);
  layout = 'card'; // layout view results: card and list
  sort = [
    {
      value: '',
      title: 'Relevantie',
    },
    {
      value: 'street',
      title: 'Titel A-Z',
    },
    {
      value: '-street',
      title: 'Titel Z-A',
    },
  ];

  isMobile = false;
  showMobileFilters = false;

  // layout view results: card and list
  views = [
    {
      value: 'card',
      title: 'Tegels',
      active: false,
    },
    {
      value: 'list',
      title: 'Lijst',
      active: false,
    },
  ];

  sortOption: any = '';

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private searchService: SearchService,
    private metaTitle: Title,
    private meta: Meta,
    private langService: LanguageService,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(DOCUMENT) private document: any,
    @Optional() @Inject('BASE_URL') private BASE_URL: string
  ) {}

  async ngOnInit() {
    this.metaTitle.setTitle(this.dictionary.transform('home_title'));

    this.meta.addTags([
      {
        property: 'og:title',
        content: `${this.dictionary.transform('home_title')}`,
      },
      {
        property: 'og:description',
        // tslint:disable-next-line:max-line-length
        content: `Muller Bedrijfsonroerendgoed is een gespecialiseerd bedrijfsmakelaarskantoor met ruime ervaring en gedegen kennis van de markt in Zwolle en omgeving.`,
      },
      {
        property: 'og:image',
        content: `${this.BASE_URL}assets/images/header-zwolle.jpg`,
      },
    ]);

    this.views[0].active = true;

    this.route.data.subscribe(a => {
      this.results = a.results;
      this.filters = a.settings;
    });

    this.route.queryParams.pipe(throttle(val => interval(200))).subscribe(a => {
      this.searchService
        .find(a.q, a.places, a.types, a.status)
        .toPromise()
        .then(results => (this.results = results));
    });

    // check if mobile
    this.checkMobile();
  }

  updateOverview(filters: any) {
    let places = Object.keys(filters.places).filter(a => filters.places[a]);
    let types = Object.keys(filters.types).filter(a => filters.types[a]);
    let status = Object.keys(filters.status).filter(a => filters.status[a]);

    places = places.length > 0 ? places : null;
    types = types.length > 0 ? types : null;
    status = status.length > 0 ? status : null;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { q: filters.q, places, types, status },
      queryParamsHandling: 'merge',
    });
  }

  setView(view) {
    this.layout = view.value;
    const resetButtons = this.views.filter(v => v.active === true);
    const setButton = this.views.filter(v => v.value === view.value);

    resetButtons[0].active = false;
    setButton[0].active = true;
  }

  checkMobile() {
    if (isPlatformBrowser(this.platformId)) {
      if (Utility.getBreakpoint() === 'breakpoint-mobile') {
        this.isMobile = true;
        // this.layout = 'list';
        this.setView(this.views[1]); // set list view
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkMobile();
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      this.document.querySelector('body').classList.add('set--calendar');
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlRewrite'
})
export class UrlRewritePipe implements PipeTransform {
  transform(value: string): string {
    if (typeof value !== 'string') { return ''; }
    return value
      .replace(/ /g, '-')
      .replace(/&/g, 'en')
      .replace(/!/g, '')
      .replace(/\./g, '')
      .replace(/,/g, '')
      .replace(/\//g, '')
      .replace(/\?/g, '')
      .replace(/\+/g, '')
      .toLowerCase();
  }
}

import { environment } from '../../../environments/environment';

export const appSettings = {
  // API_URL: 'http://127.0.0.1:8000/api'
  // API_URL: 'https://bk.echtinteractief.nl/api' // server
  production: environment.production,
  googleMapApiKey: 'AIzaSyC-MnVluH02PwHAp0VJIyBoM2eFX3e4Sq4',
  developmentServer: 'https://mullerbog.fstr.io',
  productionServer: 'https://mullerbog.nl'
};

import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  PLATFORM_ID,
  HostListener,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Building } from '../../interfaces/building';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { BuildingSummary } from '../../classes/building-summary';
import { Utility } from '../../../core/classes/utility';
import { PortfolioService } from '../../services/portfolio.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-building',
  templateUrl: './building.component.html',
})
export class BuildingComponent implements OnInit, OnDestroy {
  building: Building;
  relatedObjects: BuildingSummary[];
  isPlatformBrowser = false;
  currentUri = '';
  showAllRentalTerms = false;
  showAllPurchaseTerms = false;
  getTotalRentalTerms = [];
  getTotalPurchaseTerms = [];
  isMobile = false;

  rentalTermsOrder = [
    'acceptance',
    'rentalPriceSpecification',
    'btw',
    'serviceCosts',
    'promotionalContribution',
    'rentalPayment',
    'rentalPeriod',
    'rentalAgreement',
    'rentalPriceIndexing',
    'guaranty',
    'noticePeriod',
    'reserve',
    'stateOfDelivery',
  ];

  purchaseTermsOrder = [
    'acceptance',
    'stateOfDelivery',
    'purchasePriceSpecification',
    'btw',
    'guaranty',
    'serviceCosts',
    'noticePeriod',
    'reserve',
    'homeOwnersAssociationContribution',
    'ozb',
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: PortfolioService,
    private metaTitle: Title,
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(DOCUMENT) private document: any
  ) {}

  async ngOnInit() {
    this.building = this.route.snapshot.data.building;
    this.relatedObjects = await this.service
      .related(this.building.id)
      .toPromise();
    this.isPlatformBrowser = isPlatformBrowser(this.platformId);

    if (isPlatformBrowser(this.platformId)) {
      this.currentUri = this.router.url;
      this.checkMobile();

      if (this.building.rentalTerms !== null) {
        this.getTotalRentalTerms = Object.entries(
          this.building.rentalTerms
        ).filter(d => d[1] !== null);
        this.showAllRentalTerms = this.getTotalRentalTerms.length <= 6;
      }

      if (this.building.purchaseTerms !== null) {
        this.getTotalPurchaseTerms = Object.entries(
          this.building.purchaseTerms
        ).filter(d => d[1] !== null);
        this.showAllPurchaseTerms = this.getTotalPurchaseTerms.length <= 6;
      }
    }

    this.route.data.subscribe(c => {
      this.building = c.building;
      window.scroll(0, 0);
      // this.scroll(this.document.querySelector('body'));
    });

    // tslint:disable-next-line:max-line-length
    this.metaTitle.setTitle(
      `${this.building.street} ${this.building.houseNumber || ''}, ${
        this.building.place
      } - Muller Bedrijfsonroerendgoed`
    );

    this.meta.addTags([
      {
        property: 'og:title',
        content: `${this.building.street} ${this.building.houseNumber || ''}, ${
          this.building.place
        } - Muller Bedrijfsonroerendgoed`,
      },
      {
        property: 'og:description',
        // tslint:disable-next-line:max-line-length
        content: this.building.description,
      },
      {
        property: 'og:image',
        content: this.building.gallery[0].thumbnail,
      },
    ]);
  }

  checkMobile() {
    if (isPlatformBrowser(this.platformId)) {
      if (Utility.getBreakpoint() === 'breakpoint-mobile') {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkMobile();
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      // window.scrollTo(0, 0);
    }
  }

  scroll(el) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  toMailSubject() {
    // tslint:disable-next-line:max-line-length
    return {
      emailSubject:
        'Ik heb interesse in object ' +
        this.building.street +
        ' ' +
        this.building.houseNumber +
        ' ' +
        this.building.place,
    };
  }

  toTop() {
    window.scrollTo(0, 0);
  }
}

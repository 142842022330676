import { TeaserData } from '../../core/interfaces/teaser-data';
import { ITeasable } from '../../core/interfaces/ITeasable';

export class BuildingSummary implements ITeasable {
  id: number;
  street: string;
  houseNumber: string;
  postalCode: string;
  place: string;
  mainImage?: string;
  surfaceSize: number;
  price?: string;
  types: { id: number; type: string }[];
  status?: { id: number; status: string };
  unavailable?: boolean;
  featured: boolean;
  target?: string;
  location: { latitude: string; longitude: string };
  referentialText?: string;
  inReferences?: boolean;

  static FromJSON(json: any) {
    return Object.assign(new this(), json);
  }

  ToTeaser(): TeaserData {
    return {
      title: this.street + ' ' + (this.houseNumber || ''),
      text: this.postalCode + ' ' + this.place,
      img: this.mainImage,
      surfaceSize: this.surfaceSize,
      status: this.status,
      types: this.types,
      price: this.price,
      unavailable: this.unavailable,
      routerLink: ['/aanbod', this.id, this.target],
      referentialText: this.referentialText,
      inReferences: this.inReferences
    };
  }
}

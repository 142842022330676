<div class="loading" *ngIf="loading">

  <div class="loading__indicator type-spinner" [class.is--active]="loading">

    <svg class="loading__indicator__item is--bounce1" width="23" height="23" viewBox="0 0  23 23" xmlns="http://www.w3.org/2000/svg">
      <g fill-rule="nonzero" fill="none">
        <path d="M3.136 0h16.728A3.136 3.136 0 0 1 23 3.136v16.728A3.136 3.136 0 0 1 19.864 23H3.136A3.136 3.136 0 0 1 0 19.864V3.136A3.136 3.136 0 0 1 3.136 0z"
          fill="#452572" />
        <path d="M14.636 10.977h3.92c.867 0 1.569.702 1.569 1.568v3.92c0 .867-.702 1.57-1.568 1.57h-3.92a1.568 1.568 0 0 1-1.569-1.57v-3.92c0-.866.702-1.568 1.568-1.568z"
          fill="#C62168" />
      </g>
    </svg>


    <svg class="loading__indicator__item is--bounce2" viewBox="0 0  23 23" width="23" height="23" xmlns="http://www.w3.org/2000/svg">
      <g fill-rule="nonzero" fill="none">
        <path d="M4.023 0H20.75a3.136 3.136 0 0 1 3.136 3.136v16.728A3.136 3.136 0 0 1 20.75 23H4.023a3.136 3.136 0 0 1-3.137-3.136V3.136A3.136 3.136 0 0 1 4.023 0z"
          fill="#0095C6" />
        <path d="M13.955 2.09h1.568c.866 0 1.568.703 1.568 1.57v15.158c0 .866-.702 1.568-1.568 1.568h-1.568a1.568 1.568 0 0 1-1.569-1.568V3.66c0-.866.702-1.568 1.569-1.568z"
          fill="#452572" />
      </g>
    </svg>


    <svg class="loading__indicator__item is--bounce3" width="23" height="23" viewBox="0 0  23 23" xmlns="http://www.w3.org/2000/svg">
      <g fill-rule="nonzero" fill="none">
        <path d="M3.91 0h16.726a3.136 3.136 0 0 1 3.137 3.136v16.728A3.136 3.136 0 0 1 20.636 23H3.91a3.136 3.136 0 0 1-3.136-3.136V3.136A3.136 3.136 0 0 1 3.909 0z"
          fill="#C62168" />
        <path d="M11.227 5.227h8.364c.866 0 1.568.702 1.568 1.568v6.012c0 .866-.702 1.568-1.568 1.568h-8.364a1.568 1.568 0 0 1-1.568-1.568V6.795c0-.866.702-1.568 1.568-1.568z"
          fill="#452572" />
      </g>
    </svg>

  </div>
</div>

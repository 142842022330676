export const mapStyles = [
  {
      'featureType': 'landscape.man_made',
      'elementType': 'geometry',
      'stylers': [
          {
              'color': '#f9f5ed'
          },
          {
              'saturation': '0'
          }
      ]
  },
  {
      'featureType': 'landscape.natural',
      'elementType': 'geometry',
      'stylers': [
          {
              'color': '#d0e3b4'
          }
      ]
  },
  {
      'featureType': 'landscape.natural.terrain',
      'elementType': 'geometry',
      'stylers': [
          {
              'visibility': 'off'
          }
      ]
  },
  {
      'featureType': 'poi',
      'elementType': 'labels',
      'stylers': [
          {
              'visibility': 'off'
          }
      ]
  },
  {
      'featureType': 'poi.attraction',
      'elementType': 'all',
      'stylers': [
          {
              'visibility': 'on'
          }
      ]
  },
  {
      'featureType': 'poi.business',
      'elementType': 'all',
      'stylers': [
          {
              'visibility': 'off'
          }
      ]
  },
  {
      'featureType': 'poi.medical',
      'elementType': 'geometry',
      'stylers': [
          {
              'color': '#fbd3da'
          }
      ]
  },
  {
      'featureType': 'poi.park',
      'elementType': 'geometry',
      'stylers': [
          {
              'color': '#bde6ab'
          }
      ]
  },
  {
      'featureType': 'poi.sports_complex',
      'elementType': 'all',
      'stylers': [
          {
              'visibility': 'on'
          }
      ]
  },
  {
      'featureType': 'road',
      'elementType': 'geometry.stroke',
      'stylers': [
          {
              'visibility': 'off'
          }
      ]
  },
  {
      'featureType': 'road',
      'elementType': 'labels',
      'stylers': [
          {
              'visibility': 'off'
          }
      ]
  },
  {
      'featureType': 'road.highway',
      'elementType': 'geometry.fill',
      'stylers': [
          {
              'color': '#fcfcdd'
          },
          {
              'saturation': '0'
          }
      ]
  },
  {
      'featureType': 'road.highway',
      'elementType': 'geometry.stroke',
      'stylers': [
          {
              'color': '#efd151'
          },
          {
              'visibility': 'on'
          }
      ]
  },
  {
      'featureType': 'road.arterial',
      'elementType': 'geometry.fill',
      'stylers': [
          {
              'color': '#ffffff'
          }
      ]
  },
  {
      'featureType': 'road.arterial',
      'elementType': 'geometry.stroke',
      'stylers': [
          {
              'color': '#dcdcdc'
          },
          {
              'visibility': 'on'
          }
      ]
  },
  {
      'featureType': 'road.local',
      'elementType': 'geometry.fill',
      'stylers': [
          {
              'visibility': 'on'
          },
          {
              'color': '#ffffff'
          }
      ]
  },
  {
      'featureType': 'road.local',
      'elementType': 'geometry.stroke',
      'stylers': [
          {
              'visibility': 'on'
          },
          {
              'color': '#dedbd3'
          }
      ]
  },
  {
      'featureType': 'transit.station.airport',
      'elementType': 'geometry.fill',
      'stylers': [
          {
              'color': '#cfb2db'
          }
      ]
  },
  {
      'featureType': 'water',
      'elementType': 'geometry',
      'stylers': [
          {
              'color': '#a2daf2'
          }
      ]
  }
];

<div class="app__wrapper" [class.anim--in]="!loading">
  <app-topbar
    class="app__topbar"
    [menuItems]="menuItems"
    *ngIf="!hideNav"
  ></app-topbar>

  <div class="app__router">
    <router-outlet ></router-outlet>
  </div>

  <app-footer
    class="app__footer"
    [menuItems]="footerItems"
    *ngIf="!hideNav && !loading"
  ></app-footer>

</div>
<app-loader-indicator [loading]="loading"></app-loader-indicator>

import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { ContentModule } from "./content/content.module";
import { PortfolioModule } from "./portfolio/portfolio.module";
// import 'hammerjs';
// import 'mousetrap';
import { GalleryModule } from "@ks89/angular-modal-gallery";

@NgModule({
  declarations: [AppComponent],
  imports: [
    GalleryModule,
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    AppRoutingModule,
    CoreModule,
    ContentModule,
    PortfolioModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

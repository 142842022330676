import { Component, OnInit, Input } from "@angular/core";
import { Building } from "../../interfaces/building";
import {
  Image,
  PlainGalleryConfig,
  PlainGalleryStrategy,
  Description,
  DescriptionStrategy,
  PlainGalleryLayout,
  ModalGalleryConfig,
  ModalGalleryRef,
  ModalGalleryService,
} from "@ks89/angular-modal-gallery";

@Component({
  selector: "app-building-gallery",
  templateUrl: "./building-gallery.component.html",
})
export class BuildingGalleryComponent implements OnInit {
  @Input() building: Building;

  customPlainGalleryRowConfig: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.CUSTOM,
    layout: {},
  };
  customDescription: Description = {
    strategy: DescriptionStrategy.ALWAYS_VISIBLE,
    imageText: "",
    numberSeparator: " / ",
    beforeTextDescription: "  ",
    style: {
      bgColor: "rgba(255,0,0,0)",
      textColor: "#fff",
    },
  };

  constructor(private gallery: ModalGalleryService) {}

  ngOnInit() {}

  getGallery() {
    const images: Image[] = [];

    this.building.gallery.forEach((image, index) => {
      images.push(
        new Image(index, {
          img: image.thumbnail + "?maxwidth=1600&format=jpg",
          title: image.title,
          description: image.caption,
        })
      );
    });

    return images;
  }

  openImageModalRow(i) {
    this.customPlainGalleryRowConfig = Object.assign(
      {},
      this.customPlainGalleryRowConfig,
      { layout: {} }
    );
  }

  openModal(imageIndex: number): void {
    console.log(this.building.id, imageIndex);
    const dialogRef: ModalGalleryRef = this.gallery.open({
      id: this.building.id,
      images: this.getGallery(),
      currentImage: this.getGallery()[imageIndex],
      libConfig: {
        enableCloseOutside: true,
        dotsConfig: { visible: false },
        previewConfig: { visible: false, number: 10 },
        slideConfig: {
          infinite: true,
          sidePreviews: { show: false, size: { height: "0", width: "0" } },
        },
        currentImageConfig: {
          description: this.customDescription,
          loadingConfig: { enable: false, type: 1 },
        },
      },
    } as ModalGalleryConfig) as ModalGalleryRef;
  }

  setWidth() {
    const count = this.building.gallery.length;
    const itemWidth = 15; //15
    let width = (itemWidth * this.building.gallery.length) / 2;

    if (count === 1) {
    } else if (count === 2) {
      width = width + 2 * itemWidth;
    } else if (count === 3) {
      width = width + 3 * itemWidth;
    } else if (count === 4) {
      width = width + 4 * itemWidth;
    } else if (count === 5) {
      width = width + 5 * itemWidth;
    } else {
      width = width + 6 * itemWidth;
    }

    return width;
  }

  setIndex(c) {
    const output = [];

    c.forEach((item, index) => {
      output.push(index + 1);
    });

    return output.join(" ");
  }
}
